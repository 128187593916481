import React from 'react';

export const Rectangle = ({ className }) => (
  <svg
    width="50"
    height="4"
    viewBox="0 0 50 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="50" height="3.0" rx="3.0" fill="#5E5E5E" />
  </svg>
);
