import React from 'react';
import AuthPage from './application-center/login-center';

export const LoginCenter = AuthPage;

export const DashboardPage = React.lazy(() => import('./modules/dashboard'));
export const LandingPage = React.lazy(() => import('./modules/login/landing'));
export const UnderConstruction = React.lazy(() =>
  import('./components/UnderConstruction'),
);

export const routes = [
  {
    path: '/view/:service',
    component: React.lazy(() => import('./modules/dashboard/PerformanceNav')),
  },
];
