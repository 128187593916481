import React, { useState, useEffect } from 'react';
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { fetchLayout } from "../../redux/reducers/index";

import { getAPIHost } from '../../utils/helper';
import PopupConfirm from '../../components/popupConfirm/popupConfirm';

import jwt from "jsonwebtoken";

import axios from 'axios';
import { Box, FormControlLabel, Radio, RadioGroup, FormControl, Typography } from '@mui/material';


import Cookies from 'universal-cookie';

import * as ls from '../../utils/loginSession';

import _ from 'lodash'

import { logoutSession } from '../../utils/login';

function AboPicker() {

  // from contentstack
  const [layoutCS, setLayoutCS] = useState({});

  const contentStackData = useSelector(state => state?.layoutState?.layout);
  const dispatch = useDispatch();

  const [selectedAbo, setSelectedAbo] = useState({});

  const [idToken] = useState(jwt.decode(localStorage.getItem('idToken')))
  
  const [accounts, setAccounts] = useState([]);

  const [refreshToken, setRefreshToken] = useState("")

  const [showProceedErrorPopup, setShowProceedErrorPopup] = useState(false);

  const [showLoginErrorPopup, setShowLoginErrorPopup] = useState(false);

  const [showNoAccPopup, setShowNoAccPopup] = useState(false);

  useEffect(() => {
    if (!contentStackData) {
      console.log("contentStackData", contentStackData);
      dispatch(fetchLayout())
    }
  }, []);

  useEffect(() => {
    if (contentStackData !== undefined) {
      setLayoutCS(contentStackData?.layout?.mp);
    }
  }, [contentStackData]);

  const handleProceed = async () => {
    // console.log("see selectedAbo",selectedAbo)

    if(!_.isEmpty(selectedAbo)){
      const apiUrl = getAPIHost();

      let data = {
        aboId: selectedAbo.abo,
        affCode: selectedAbo.sales_plan_aff,
        refreshToken: refreshToken
      }

      try {
        let res = await axios.post(`${apiUrl}/auth/requestRefreshToken`,
        data)

        if(res?.status == 200) {

          const cookies = new Cookies();

          //make sure access token is cleared before setting new 1, to avoid duplicate
          cookies.remove('accessToken');
          
          let exp = new Date().getTime() + ls.getLoginMaxIdle() * 60 * 1000;
          cookies.set('accessToken', res?.data?.access_token || '', ls.cookieOption(exp));
          localStorage.setItem('idToken', res?.data?.id_token);
          localStorage.setItem('authCode', res?.data?.refresh_token);
        } else {
          setShowLoginErrorPopup(true)
        } 

      } catch (err) {
        setShowLoginErrorPopup(true)
        console.log("Refresh Token Error : ",err);
      }

      localStorage.setItem('loginFlag', 'true');
      window.location.href = '/home';
    } else {
      setShowProceedErrorPopup(true)
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = getAPIHost();
      const validCountry = ['my', 'bn', 'sg'];
  
      let data = {
        code: localStorage.getItem('authCode')
      };
  
      try {
        let res = await axios.post(`${apiUrl}/auth/requestToken`, data);
  
        if (res?.status === 200) {
          console.log("res?.data", res?.data);
          setRefreshToken(res?.data);

          console.log("idToken.accounts",idToken.accounts)
          const filteredAccounts = _.filter(idToken.accounts, account => {
            return account.status.toLowerCase() === "active" &&
                  validCountry.includes(account.country.toLowerCase()) &&
                  account.sales_plan_aff === "100" &&
                  account.acct_type.toLowerCase() === "amwaybusiness" &&
                  account.acct_subtype.toLowerCase() === "businessowner";
            });
  
          console.log("filteredAccounts",filteredAccounts)
  
          setAccounts(filteredAccounts);
          if(filteredAccounts?.length <= 0) {
            setShowNoAccPopup(true)
          }
        } else {
          setShowLoginErrorPopup(true)
        } 
  
      } catch (err) {
        setShowLoginErrorPopup(true)
        console.log("Token Error:", err);
      }
    };
  
    fetchData();
  }, []);

  const closeProceedErrorPopup = async () => {
    setShowProceedErrorPopup(false)
  }

  const closeLoginErrorPopup = async () => {
    setShowLoginErrorPopup(false)
    logoutSession();
    logoutRedirect();
  }

  const closeNoAccPopup = async () => {
    setShowNoAccPopup(false)
    logoutSession();
    logoutRedirect();
  }

  const logoutRedirect = async () => {
    const currentPath = window.location.hostname;
    if (currentPath === 'localhost' || currentPath === '172.0.0.1') {
      window.location.href = '/testPost';

    } else if (currentPath === 'uat.biz.amway.my' || currentPath === 'biz.amway.my' || currentPath === 'psup.biz.amway.my') {
      window.location.href = `${process.env.REACT_APP_AMWAYHOMEPAGE_URL_MY}/`;

    } else if (currentPath === 'uat.biz.amway.sg' || currentPath === 'biz.amway.sg' || currentPath === 'psup.biz.amway.sg') {
      window.location.href = `${process.env.REACT_APP_AMWAYHOMEPAGE_URL_SG}/`;

    } else if (currentPath === 'uat.biz.amway.com.bn' || currentPath === 'biz.amway.com.bn' || currentPath === 'psup.biz.amway.com.bn') {
      window.location.href = `${process.env.REACT_APP_AMWAYHOMEPAGE_URL_BN}/`;

    }
  }


  return (
    <div>
      <PopupConfirm
      showPopup={true}
      title={layoutCS?.profile_selection?.title || "Please select your profile"}
      okBtn={{
        text: layoutCS?.profile_selection?.proceed || "Proceed",
        action: handleProceed,
      }}
      hideCloseButton={true}

    >
      <div>{layoutCS?.profile_selection?.description || "You are linked to multiple accounts. Please choose the account you wish to operate with."}</div>
      <br />
      <FormControl class="col-12" component="fieldset">
        <RadioGroup aria-label="options" name="options" value={selectedAbo}>
          <Box display="flex" flexDirection="column">
            {accounts.map((item,index) => {
              return (
              <Box key={index} border={1} p={1} display="flex" alignItems="center" onClick={() => setSelectedAbo(item)}>
                <FormControlLabel
                  class="box-item"
                  value={item}
                  control={<Radio />}
                  label={
                    <Box>
                      <Typography variant="subtitle1">{item.abo_name}</Typography>
                      <Typography variant="caption">{item.abo}</Typography>
                    </Box>
                  }
                />
              </Box>
            )})}
          </Box>
        </RadioGroup>
      </FormControl>
    
    </PopupConfirm>
    <PopupConfirm
      showPopup={showProceedErrorPopup}
      title={layoutCS?.profile_selection?.title_before_proceed || "Please select a profile before proceed"}
      okBtn={{
        text: layoutCS?.profile_selection?.okBtn || "OK",
        action: closeProceedErrorPopup,
      }}
      hideCloseButton={true}

    >

    </PopupConfirm>

    <PopupConfirm
      showPopup={showLoginErrorPopup}
      title={layoutCS?.profile_selection?.login_error || "Login Error"}
      okBtn={{
        text: layoutCS?.profile_selection?.ok_button || "OK",
        action: closeLoginErrorPopup,
      }}
      hideCloseButton={true}
    >
    <div>{layoutCS?.profile_selection?.login_error_description || "Oops! We hit a snag trying to log you in. Please try logging in again."}</div>

    </PopupConfirm>

    <PopupConfirm
      showPopup={showNoAccPopup}
      title={layoutCS?.profile_selection?.no_acc_title || "No Account"}
      okBtn={{
        text: layoutCS?.profile_selection?.ok_button || "OK",
        action: closeNoAccPopup,
      }}
      hideCloseButton={true}
    >
    <div>{layoutCS?.profile_selection?.no_acc_description || "No ABO Account"}</div>

    </PopupConfirm>
    </div>
  );
  
}

export default AboPicker;
