import React from "react";
import styles from './index.scss'
import PropTypes from 'prop-types';
 
const CheckBox = ({ value, text, onChange,checked,id,disabled }) => {
    return (<div>
    <input
      type="checkbox"
      id={id}
      value={value}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <label className={styles['label']} htmlFor={id}>
      &nbsp;&nbsp;&nbsp;{text}
    </label>
  </div>)
}

CheckBox.propTypes = {
    value: PropTypes.string,
    text: PropTypes.string, 
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    id: PropTypes.string,
    disabled:PropTypes.bool
};

export default CheckBox
