import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Close from './assets/Close.png';
import { disableEnableScroll } from '../../utils/util';
import './popupConfirm.scss';
import Drawer from '../Drawer/Drawer';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { MdClose } from 'react-icons/md';

const PopupConfirm = props => {
  const {
    showPopup,
    title,
    titleLine,
    children,
    okBtn,
    cancelBtn,
    showTextTemplate,
    autoDisable,
    styles,
    showArrowBack,
    hideCloseButton,
    contentStyles,
    resetBtn,
  } = props;

  useEffect(() => {
    //if (showPopup)
    if (autoDisable) {
      disableEnableScroll(showPopup);
      return () => {
        disableEnableScroll(false);
      };
    }
  }, [showPopup, autoDisable]);

  const onBackdropClick = () => {
    cancelBtn.action();
    document.body.style.overflowY = 'auto';
    document.body.style.paddingRight = 'inherit';
  };

  const handleClickInside = e => {
    e.stopPropagation();
  };

  const handleOkBtn = () => {
    okBtn.action();
  };
  const handleCancelBtn = () => {
    cancelBtn.action();
  };
  const handleResetBtn = () => {
    resetBtn.action();
  };
  return (
    <Drawer
      variant="temporary"
      anchor="bottom"
      open={showPopup}
      ModalProps={{ onBackdropClick: () => onBackdropClick() }}
    >
      <div className={'confirm_container'} onMouseDown={onBackdropClick}>
        <div
          style={styles}
          className={`${'body_container'} ${
            showTextTemplate ? 'txtTemplateContainer' : ''
          }`}
          onMouseDown={handleClickInside}
        >
          <div className={'close_bar'}>
            <button
              tabIndex="0"
              aria-label="Close"
              type="button"
              className={'close_button'}
              onClick={handleCancelBtn}
              style={{visibility: hideCloseButton ? 'hidden' : 'visible'}}
            >
              <img src={Close} />
            </button>
            <button
              className={'close_notch'}
              onClick={handleCancelBtn}
            ></button>
          </div>
          <div className={`${showTextTemplate ? 'txtTemplateFrame' : ''}`}>
            <div className={`${'title'} ${titleLine ? 'withLine' : ''}`}>
              {showArrowBack && (<div className={'arrowback_container'} onClick={handleCancelBtn}><MdOutlineArrowBackIos/>&nbsp;{title}</div>)}
              {!showArrowBack && (<div className={'non_arrowback_container'}>{title}</div>)}
              {title && <div className={"filter_close"} onClick={handleCancelBtn}>
                <MdClose size={22} />
              </div>}
            </div>
            <div className={`${'content'}`} style={contentStyles}>{children}</div>
          </div>
          <div
            className={`${'buttonGroup'} ${
              showTextTemplate ? 'desktopHide' : ''
            }`}
          >
            {resetBtn.text ? (
              <button className={'btnReset'} onClick={handleResetBtn}>
                {resetBtn.text}
              </button>
            ) : (cancelBtn.text && !showArrowBack) && (
              <button className={'btnCancel'} onClick={handleCancelBtn}>
                {cancelBtn.text}
              </button>
            )}
            {okBtn.text && (
              <button className={'btnOK'} onClick={handleOkBtn}>
                {okBtn.text}
              </button>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

PopupConfirm.defaultProps = {
  title: '',
  titleLine: false,
  okBtn: {
    text: '',
    action: () => {
      console.log('OK');
    },
  },
  cancelBtn: {
    text: '',
    action: () => {
      console.log('CANCEL');
    },
  },
  showPopup: false,
  showTextTemplate: false,
  autoDisable: true,
  styles: {},
  showArrowBack: false,
  hideCloseButton: false,
  contentStyles: {},
  resetBtn: {
    text: '',
    action: () => {
      console.log('RESET');
    },
  },
};

PopupConfirm.propTypes = {
  title: PropTypes.string,
  titleLine: PropTypes.bool,
  okBtn: PropTypes.object,
  cancelBtn: PropTypes.object,
  showPopup: PropTypes.bool,
  showTextTemplate: PropTypes.bool, // for desktop only
  autoDisable: PropTypes.bool,
  styles: PropTypes.object,
  showArrowBack: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  contentStyles: PropTypes.object,
  resetBtn: PropTypes.object,
};

export default PopupConfirm;
