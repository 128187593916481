module.exports = global.enums = {
  userGroups: {
    SystemAdmin: 1,
    StoreManager: 2,
  },

  notificationType: {
    E: 'error',
    I: 'info',
    W: 'warning',
    S: 'success',
  },
};
