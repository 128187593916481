import React, { useEffect } from "react";
import './style.scss';

function Mobile() {
  useEffect(() => {
    document.getElementById("datalayer_js")?.remove();
    //document.getElementById("spa_js")?.remove();
    document.getElementById("integration_js")?.remove();
  }, []);

  return (
    <div className='frame_main_container'>
      <div className='frame_container' style={{ width: "395px" }}>
        <iframe src="/" width="395" height="710"></iframe>
      </div>
    </div>
  );
}

export default Mobile;
