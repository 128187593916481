// Login session functions put here
//import Cookies from "universal-cookie";
import jwt from "jsonwebtoken";

export const cookieOption = (exDay) => {
  if (typeof window !== "undefined") {
    let expDate = new Date(exDay);
    if (exDay <= 366)
      expDate = setCookieExp(exDay)
    if (window.location.protocol === "https:") {
      return {
        expires: expDate,
        path: "/",
        domain: window.location.hostname,
        secure: true
      };
    } else {
      return {
        expires: expDate,
        path: "/",
      };
    }
  }
  return {};
};

export const setCookieExp = (exDay) => {
  const d = new Date();
  d.setTime(d.getTime() + exDay * 24 * 60 * 60 * 1000);
  return d;
};

export const loginCookieExp = () =>
  (typeof window === "undefined" ? process.env : window.appConfig || {})
    .LOGIN_COOKIES_EXP || 1;

export const getLoginEnv = () =>
  (typeof window === "undefined" ? process.env : window.appConfig || {})
    .REACT_APP_LOGIN_ENV || "dev";

export const getTokenRefreshTime = () =>
  (typeof window === "undefined" ? process.env : window.appConfig || {})
    .LOGIN_REFRESH_TOKEN || 5;

export const getLoginMaxIdle = () =>
  (typeof window === "undefined" ? process.env : window.appConfig || {})
    .LOGIN_MAX_IDLE || 60;

export const getLoginTokenValidity = () =>
  (typeof window === "undefined" ? process.env : window.appConfig || {})
    .LOGIN_SDK_TOKEN_VALIDITY || 30;

//============================================================================//

export const getAccessHeader = (ctxCookieOrStates = "") => {
  const tokens = getTokens(ctxCookieOrStates);
  return {
    Authorization: "Bearer " + tokens.accessToken,
  };
};

export const getAccessHeaderNew = (ctxCookieOrStates = "") => {
  const tokens = getTokens(ctxCookieOrStates);
  return {
    authorization: tokens.accessToken,
  };
};

export const getTokens = (ctxCookieOrStates = "") => {
  let accessToken = "";
  let idToken = "";
  if (
    typeof window !== "undefined" ||
    (typeof ctxCookieOrStates === "string" && ctxCookieOrStates !== "")
  ) {
    //const cookies = new Cookies(ctxCookieOrStates);
    //accessToken = cookies.get("accessToken") || '';
    //idToken = cookies.get("idToken") || '';

    accessToken = localStorage.getItem('accessToken') || '';
    idToken = localStorage.getItem('idToken') || '';
  } else if (
    ctxCookieOrStates &&
    ctxCookieOrStates.loginState &&
    ctxCookieOrStates.loginState.login
  ) {
    accessToken = ctxCookieOrStates.loginState.login.accessToken;
    idToken = ctxCookieOrStates.loginState.login.idToken;
  }

  return {
    accessToken: accessToken,
    idToken: idToken,
  };
};

export const getADA = (ctxCookieOrStates = "") => {
  let ada = "";
  if (
    typeof window !== "undefined" ||
    (typeof ctxCookieOrStates === "string" && ctxCookieOrStates !== "")
  ) {
    //const cookies = new Cookies(ctxCookieOrStates);
    //ada = cookies.get("ada") || "";
    ada = localStorage.getItem('abo') || jwt?.decode(localStorage.getItem('accessToken'))?.account?.abo;

  } else if (
    ctxCookieOrStates &&
    ctxCookieOrStates.loginState &&
    ctxCookieOrStates.loginState.login
  ) {
    ada = ctxCookieOrStates.loginState.login.ada;
  }
  return ada;
};

export const getAll = (
  ctxCookieOrState = "",
  ctx = { res: null, req: null },
  redirectLoginIfNotFound = false
) => {
  if (!ctx) {
    ctx = { res: null, req: null };
  }
  const tokens = getTokens(ctxCookieOrState);
  const ada = localStorage.getItem('abo') || jwt?.decode(tokens.accessToken)?.account?.abo;
  if (tokens.accessToken) {
    return {
      accessHeader: {
        Authorization: "Bearer " + tokens.accessToken,
      },
      accessToken: tokens.accessToken,
      idToken: tokens.idToken,
      ada: ada,
    };
  } else {
    if (redirectLoginIfNotFound) {
      // temp disable
      if (typeof window !== "undefined") {
        // redirect to landing (to verify login first) if possible
        // if not login, landing will redirect to login
        window.location.href = "/";
      }
    }

    // if all not provided, return empty object
    return {
      accessHeader: {},
      accessToken: "",
      idToken: "",
      ada: "",
    };
  }
};
