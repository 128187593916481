import axios from '../../../../utils/axios-interceptor';
import { getAPIHost, formatDateYYYYMM } from '../../../../utils/helper';
import { getAccessHeader, getADA } from '../../../../utils/loginSession';
import { isEmpty } from '../../../../utils/helper';

export const enums = {
  SET_MY_PERFORMANCE_STATS: { 
    type: 'SET_MY_PERFORMANCE_STATS',
    state: 'myPerformanceStats'
  },
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

// My Performance
export const fetchMyPerformanceStats = params => async (dispatch, getState) => {
  let countryCode = localStorage.getItem('tokenCountry') || 'my';
  try {
    const apiUrl = getAPIHost();
    let res = await axios({
      headers: getAccessHeader(getState()),
      method: 'GET',
      url: `${apiUrl}/myPerformance/stats/${countryCode}`,
      params: {
        aff: params.aff,
        loginAda: getADA(),
        period: formatDateYYYYMM(params.period),
      },
    });
    console.log("myPerformanceStats res ", res);
    if (res?.data && res?.data?.httpStatus === "200") {
      dispatch(setState(enums.SET_MY_PERFORMANCE_STATS, { ...res.data }));
      return { ...res.data };
    }
  } catch (err) {
    console.log(err);
  }
};

// state
const myPerfStatsState = (state = {}, action) => {
  switch (action.type) {
    // for special case
    
    // for use enum
    default:
      if (enums[action.type]) {
        if (action.type === 'SET_MY_PERFORMANCE_STATS') {
          let tempArrState = [];
          if (!isEmpty(state)) {
            tempArrState = [...state[enums[action.type].state]]
          }
          tempArrState.push(action.payload);
          return {
            ...state,
            [enums[action.type].state]: tempArrState,
          }
        }
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

    // for default
    return state;
  }
};

export default {
  myPerfStatsState,
};
