import Cookies from 'universal-cookie';
//import * as ls from './loginSession';
import jwt from "jsonwebtoken";

//========================================================================//

export const loginSession = () => {
  if (typeof window !== 'undefined') {
    const cookies = new Cookies();
    localStorage.setItem('accessToken', cookies.get('accessToken'));

    const accessToken = localStorage.getItem('accessToken');

    localStorage.setItem('aff', jwt?.decode(accessToken)?.account?.sales_plan_aff);
    localStorage.setItem('abo', jwt?.decode(accessToken)?.account?.abo);
    localStorage.setItem('tokenCountry', jwt?.decode(accessToken)?.account?.country.toLowerCase());
    localStorage.setItem('aboName', jwt?.decode(accessToken)?.account?.abo_name);
    localStorage.setItem('accSubType', jwt?.decode(accessToken)?.account?.acct_subtype);
    localStorage.setItem('accType', jwt?.decode(accessToken)?.account?.acct_type);
    localStorage.setItem('renewalFlag', jwt?.decode(accessToken)?.account?.renewal_flag);
    localStorage.setItem('level', jwt?.decode(accessToken)?.account?.level);

    localStorage.setItem('globalPartyID', jwt?.decode(accessToken)?.gbl_partyid);
  } else {
    console.log('Login session valid on browser session only');
  }
};

export const logoutSession = () => {
  if (typeof window !== 'undefined') {
    const cookies = new Cookies();
    console.log('Remove cookies');
    // reset general login data
    cookies.remove('accessToken');
    cookies.remove('idToken');
    //cookies.set('accessToken', '', ls.cookieOption(-1));
    //cookies.set('idToken', '', ls.cookieOption(-1));
    //cookies.set('refreshToken', '', ls.cookieOption(-1));
    //cookies.set('tokenExpires', '', ls.cookieOption(-1));
    //cookies.set('user', '', ls.cookieOption(-1));

    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('loginFlag');
    localStorage.removeItem('authCode');

    localStorage.removeItem('aff');
    localStorage.removeItem('abo');
    localStorage.removeItem('tokenCountry');
    localStorage.removeItem('aboName');
    localStorage.removeItem('accSubType');
    localStorage.removeItem('accType');
    localStorage.removeItem('renewalFlag');
    localStorage.removeItem('level');

    localStorage.removeItem('globalPartyID');
    localStorage.removeItem('pinNumber');

    localStorage.removeItem('downlineInfo');
    localStorage.removeItem('couponInfo');

    localStorage.removeItem('disableCompatibilityPopup');
    sessionStorage.removeItem('sendReminderList');
    sessionStorage.removeItem('sendConversionList');
    sessionStorage.removeItem('sendCouponReminderList');
  } else {
    console.log('Logout session valid on browser session only');
  }
};
